import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ component: Component, isAuth, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        (isAuth.isAuthenticated == null ? <div>loading...</div> : isAuth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          ))
      }
    />
  );
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth,
    isLoading: state.isLoading
  }; // change this line
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));