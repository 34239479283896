// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import icons
import { faDownload, faPlay, faFileAudio, faTrashAlt, faTimes, faChevronRight, faChevronDown, faFileAlt, faSyncAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(
  faDownload,
  faPlay,
  faFileAudio,
  faTrashAlt,
  faTimes,
  faChevronRight,
  faChevronDown,
  faFileAlt,
  faSyncAlt,
  faEdit
);