import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendPasswordReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import * as EmailValidator from 'email-validator';

const initialState = {
  email: '',
  emailErr: '',
  err: null
};

const noErrorState = {
  emailErr: '',
  err: null
};


class PasswordResetRequest extends Component {
  state = initialState;

  static propTypes = {
    error: PropTypes.object.isRequired,
    sendPasswordReset: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for reset error
      if (error.id === 'SEND_RESET_FAIL') {
        if (error.msg.msg) {
          this.setState({ err: error.msg.msg });
        } else {
          this.setState({ err: 'An internal error occurred.' });
        }
      } else {
        this.setState({
          err: null
        });
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validate = () => {
    let emailErr = "";
    let err = "";

    if (!EmailValidator.validate(this.state.email)) {
      emailErr = 'Please enter a valid email address.';
    }

    if (emailErr || err) {
      this.setState({ emailErr, err });
      return false;
    }
    return true;
  }

  onSubmit = e => {
    e.preventDefault();

    const isValid = this.validate();
    if (isValid) {
      const { email } = this.state;

      // clear form
      this.setState(noErrorState);

      // Attempt to login
      this.props.sendPasswordReset(email);
    }
  };

  render() {
    return (
      <div className="container max-w-xl mx-auto bg-white rounded-lg shadow-lg p-5 overflow-auto">
        <form onSubmit={this.onSubmit}>
          <h1 className="text-center text-2xl font-medium">Request Password Reset</h1>
          <p className="mt-4" htmlFor="email">Enter your email address to have a password reset email sent to you:</p>
          {this.state.err ? <label className="text-red-600">{this.state.err}</label> : null}
          <input ref={(input) => { this.emailInput = input; }}
            name="email"
            autoComplete="username"
            className="mt-2 appearance-none block w-full bg-grey-200 text-grey-darker border border-grey-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
            id="email" type="text" placeholder="Email address" onChange={this.onChange} />
          {this.state.emailErr ? <label className="text-red-600">{this.state.emailErr}</label> : null}
          <div className="float-right mt-8">
            <button className='block mt-4 p-1 pl-2 pr-2 lg:inline-block lg:mt-0 text-blue-800 hover:bg-blue-200 border border-blue-800 mr-2 bg-white rounded-full' onClick={this.toggle} type="button"> Cancel </button>
            <button className='block mt-4 p-1 pl-2 pr-2 lg:inline-block lg:mt-0 text-white hover:bg-blue-600 mr-2 bg-blue-800 border border-blue-800 rounded-full'>Submit</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(mapStateToProps, { sendPasswordReset, clearErrors })(PasswordResetRequest);