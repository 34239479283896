import React, { Component } from 'react'

export default class VolumeDropdown extends Component {
  constructor(props) {
    super(props)

    this.insertVolume = this.insertVolume.bind(this);

    this.state = {
      isOpen: false
    }
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  insertVolume(e, level) {
    e.preventDefault();

    var textArea = document.getElementById('inputText');

    //IE support
    if (document.selection) {
      textArea.focus();
      var sel = document.selection.createRange();
      sel.text = '<prosody volume=\'' + level + '\'/>';
    }
    //MOZILLA and others
    else if (textArea.selectionStart || textArea.selectionStart === '0') {
      var startPos = textArea.selectionStart;
      var endPos = textArea.selectionEnd;
      textArea.value = textArea.value.substring(0, startPos)
        + '<prosody volume=\'' + level + '\'>'
        + textArea.value.substring(startPos, endPos)
        + '</prosody>'
        + textArea.value.substring(endPos, textArea.value.length);
    } else {
      textArea.value += '<prosody volume=\'' + level + '\'></prosody>';
    }
    if (this.state.isOpen) {
      this.setState({
        isOpen: false
      });
    }
    this.props.onChange(textArea.value);
  }

  render() {
    var isOpen = this.state.isOpen;
    return (
      <div>
        <button tabIndex="-1" onClick={this.toggle} className="relative z-10 block bg-blue-700 hover:bg-blue-800 text-white font-bold py-1 px-4 mr-2 border border-teal-800 rounded">Adjust Volume:</button>
        {isOpen &&
          <div>

            <button onClick={this.toggle} tabIndex="-1" className="fixed inset-0 w-full h-full bg-black opacity-25 cursor-default"></button>

            <div className="absolute mt-2 py-2 w-30 bg-white rounded-lg border border-gray-500 shadow-xl">
              <button onClick={(e) => this.insertVolume(e, 'x-soft')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Extra Soft</button>
              <button onClick={(e) => this.insertVolume(e, 'soft')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Soft</button>
              <button onClick={(e) => this.insertVolume(e, 'medium')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Medium</button>
              <button onClick={(e) => this.insertVolume(e, 'loud')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Loud</button>
              <button onClick={(e) => this.insertVolume(e, 'x-loud')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Extra Loud</button>
            </div>

          </div>
        }
      </div>
    )
  }
}