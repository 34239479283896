import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./styles/tailwind.css";
import "./fontawesome.js";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';

ReactDOM.render(
    (<Provider store={store}><Router history={history}><App /></Router></Provider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
