import React, { useMemo, useEffect, useCallback, Fragment } from 'react';
import Table from './Table';
import { connect } from 'react-redux';
import { getRecordings, searchRecordings, getVoiceList, onDownload, onPlay, stopPreviewRecording, deleteRecording, editRecording } from '../actions/recordingActions';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateRecording from './CreateRecording';
import { NavLink } from 'react-router-dom';

function MyRecordings(props) {
  const { recordings } = props;

  useEffect(() => {
    (async () => {
      props.getRecordings();
    })();
  }, []);

  const onSearchKeyUp = event => {
    let newSearchText = event.currentTarget.value;
    props.searchRecordings(newSearchText);
  }

  function onDeleteClick(id) {
    var result = window.confirm("Are you sure you want to delete this recording?");
    if (result) {
      props.deleteRecording(id);
    }
  }

  function onEditClick(id) {
    props.editRecording(id);
  }

  function onStopPreview(id) {
    props.stopPreviewRecording(id);
  }


  MyRecordings.propTypes = {
    getRecordings: PropTypes.func.isRequired,
    recordings: PropTypes.array.isRequired
  };



  return (
    <div>
      <div className="flex">
        <div className="w-full bg-white p-6 shadow-sm rounded-sm">
          <div className="sm:flex items-center">
            <h1 className="text-3xl w-full sm:w-1/2 font-bold leading-tight text-gray-900 ">
              My Recordings
            </h1>
            <div className="w-full sm:w-1/2 sm:text-right py-3 sm:py-0">
              <NavLink to="/create-recording" className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-3 shadow-md rounded-sm">Create Recording</NavLink>
            </div>
          </div>
          <div className="mt-4 w-full">
            
            <div className="mt-1 flex rounded-md shadow-sm mb-4">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                </div>
                <input onChange={onSearchKeyUp} type="text" name="search" id="search" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-sm pl-10 sm:text-sm border-gray-300" placeholder="Search for a recording" />
              </div>
            </div>
          </div>
          {ListRecordings(props)}
        </div>
      </div>
    </div>
  )
}

function ListRecordings(props) {
  if(props.recordings.length < 1){
    return ( <div className="text-lg">You haven't created any recordings yet. Why not <NavLink to="/create-recording" className="text-blue-600 font-semibold">create one</NavLink>?</div>)
  }else{
    return (
      <div>
        {props.recordings.map((recording, index) => {
          const n = recording.path.lastIndexOf('/');
          var result = recording.path.substring(n + 1);
          result = result.substring(0, result.length - 4);

          if(recording.fileName && recording.fileName != ""){
            result = recording.fileName;
          }

          var dc = new Date(recording.dateCreated).toLocaleDateString();
          var tc = new Date(recording.dateCreated).toLocaleTimeString();

          const onDeleteClick = event => {
            var delete_result = window.confirm("Are you sure you want to delete this recording?");
            if (delete_result) {
              props.deleteRecording(event.currentTarget.getAttribute("data-id"));
            }
          }
        
          const onEditClick = event => {
            props.editRecording(event.currentTarget.getAttribute("data-id"));
          }

          return (
            <div key={recording._id} className="py-4 px-2 hover:bg-gray-100 border-b border-gray-200 md:flex md:-mx-2 items-center">
              <div className="w-full my-2 md:my-0 md:w-1/4 mx-2">
                <div className="text-lg hover:text-blue-600 cursor-pointer" data-id={recording._id} onClick={onEditClick}>{result}</div>
                <div className="text-gray-500 align-middle">
                  <svg className="align-middle inline-block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg> {dc}, {tc}
                </div>
              </div>
              <div className="w-full my-2 md:my-0 md:w-1/2 mx-2">
                <div className="border-2 border-dashed p-2 align-middle text-gray-500">
                  {recording.text.length > 140 ? recording.text.substring(0, 140) + "..." : recording.text}
                </div>
              </div>
              <div className="w-full my-2 md:my-0 md:w-1/4 mx-2 md:justify-end flex">
                <div className="text-gray-400 hover:text-blue-500" data-name={"play-btn-"+recording._id}>
                  <svg onClick={props.onPlay} data-id={recording._id}  
                  className="mx-1 align-middle inline-block h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <div className="text-xs text-center font-semibold">Play</div>
                </div>
                <div className="hidden text-gray-400 hover:text-blue-500" data-name={"stop-btn-"+recording._id}>
                  <svg onClick={() => props.stopPreviewRecording(recording._id)} data-id={recording._id}
                  className="mx-1 align-middle inline-block h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
                  </svg>
                  <div className="text-xs text-center font-semibold">Stop</div>
                </div>
                <div className="text-gray-400 hover:text-blue-500">
                  <svg data-id={recording._id} onClick={props.onDownload}
                  className="mx-1 align-middle inline-block h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                  </svg>
                  <div className="text-xs text-center font-semibold">Download</div>
                </div>
                <div className="text-gray-400 hover:text-blue-500">
                  <svg data-id={recording._id} onClick={onEditClick}
                  className="mx-1 align-middle inline-block h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  <div className="text-xs text-center font-semibold">Edit</div>
                </div>
                <div className="text-gray-400 hover:text-red-600">
                  <svg data-id={recording._id} onClick={onDeleteClick}
                  className="mx-1 align-middle inline-block h-10 w-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  <div className="text-xs text-center font-semibold">Delete</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    );
  } 
}



const mapStateToProps = state => ({
  recordings: state.recording.recordings,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { getRecordings, searchRecordings, onDownload, onPlay, stopPreviewRecording, deleteRecording, editRecording })(MyRecordings);
