import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearErrors } from '../../actions/errorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from "../../history";

class VerificationModal extends Component {
  state = {
    isOpen: true,
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { isOpen } = this.props.location.state;
    this.setState({ isOpen: isOpen });
  }

  toggle = (action, elem_trigger) => {
    // Clear errors
    this.props.clearErrors();
    var areClosing = this.state.isOpen;
    this.setState({
      isOpen: !this.state.isOpen
    });
    if (areClosing)
      history.push('/login');
    window.location.reload(false);
  };

  onSubmit = e => {
    e.preventDefault();

    this.toggle();
  };

  render() {
    const { isOpen } = this.state;
    const { email, verificationErr } = this.props.location.state;

    return (
      <div className="inline-block">
        <div id="centeredFormModal" className={`modal-wrapper ${isOpen ? "modal-is-open" : ""}`}>
          <div className="overlay close-modal" onClick={this.toggle}></div>
          <div className="modal modal-centered">
            <div className="modal-content shadow-lg p-5">
              <div className="border-b p-2 pb-3 pt-0 mb-4">
                <div className="flex justify-between items-center">
                  Account Verification
                  <span className='close-modal cursor-pointer px-3 py-1 rounded-full bg-gray-100 hover:bg-gray-200' onClick={this.toggle}>
                    <FontAwesomeIcon icon="times" />
                  </span>
                </div>
              </div>
              {/* Modal content*/}

              <div id='verification_message' className="w-full">
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3">
                    {verificationErr ? <Fragment><label className="text-red-600">{verificationErr}</label></Fragment> : <label className="block text-grey-darker mb-1">{`Email verification successfully sent to ${email}`}</label>}
                  </div>
                </div>
                <div className="mt-5">
                  <button className='bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 mr-2 rounded' onClick={this.onSubmit} type="button"> Ok </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(mapStateToProps, { clearErrors })(VerificationModal);