export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_RECORDINGS = 'GET_RECORDINGS';
export const CREATE_RECORDING = 'CREATE_RECORDING';
export const UPDATE_RECORDING = 'UPDATE_RECORDING';
export const PREVIEW_RECORDING = 'PREVIEW_RECORDING';
export const DELETE_RECORDING = 'DELETE_RECORDING';
export const DOWNLOAD_RECORDING = 'DOWNLOAD_RECORDING';
export const RECORDINGS_LOADING = 'RECORDINGS_LOADING';
export const GET_VOICE_LIST = 'GET_VOICE_LIST';
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const TRANSLATE_TEXT = 'TRANSLATE_TEXT';
export const TOKEN_RESENT = 'TOKEN_RESENT';
export const TOKEN_RESEND_FAIL = 'TOKEN_RESEND_FAIL';
export const RESET_SENT = 'RESET_SENT';
export const SEND_RESET_FAIL = 'SEND_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
