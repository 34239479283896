import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from './store';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  withCredentials: true,
  headers: {
    "content-type": "application/json"
  },
  responseType: "json"
});

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => instance.post(`/auth/refreshToken`).then(tokenRefreshResponse => {
  // grab current state
  const state = store.getState();

  state.auth.token = tokenRefreshResponse.data.token;
  localStorage.setItem('token', tokenRefreshResponse.data.token);

  failedRequest.response.config.headers['x-auth-token'] = tokenRefreshResponse.data.token;
  return Promise.resolve();
});
 
// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
