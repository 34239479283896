import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createRecording, updateRecording, translate, previewRecording, stopPreviewRecording, getVoiceList, getLanguageList } from '../actions/recordingActions';
import PropTypes from 'prop-types';
import PauseDropdown from './dropdowns/PauseDropdown';
import EmphasisDropdown from './dropdowns/EmphasisDropdown';
import SayAsDropdown from './dropdowns/SayAsDropdown';
import VolumeDropdown from './dropdowns/VolumeDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import langCodeData from '../lang_codes.json';

class CreateRecording extends Component {
  state = {
    id: null,
    fileName: '',
    inputText: '',
    gender: 'MALE',
    voice: 'en-US-Wavenet-C',
    sampleRate: '48000',
    encoding: 'MP3',
    audioPreset: 'Custom',
    isPlaying: false,
    language: 'en'
  }

  componentDidMount = () => {
    this.props.getVoiceList();
    this.props.getLanguageList();

    if (this.props.location.state && this.props.location.state.recording) {
      const { recording } = this.props.location.state;
      const n = recording.path.lastIndexOf('/');
      var fileName = recording.path.substring(n + 1);
      fileName = fileName.substring(0, fileName.length - 4);
      if (recording.fileName && recording.fileName != "") {
        fileName = recording.fileName;
      }

      var voiceName = recording.voice;

      this.setState({
        id: recording._id,
        inputText: recording.text,
        fileName: fileName,
        voice: voiceName,
        sampleRate: recording.sampleRate,
        encoding: recording.encoding,
        audioPreset: recording.audioPreset ? recording.audioPreset : "Custom"
      });
    }
    window.history.pushState(null, '');
  }

  static propTypes = {
    inputText: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool
  }

  updateInputText = inputText => {
    this.setState({ inputText })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name == "audioPreset" && e.target.value == "3CX") {
      this.setState({ ["sampleRate"]: 8000 });
      this.setState({ ["encoding"]: "LINEAR16" });
    }
  };

  onPreview = (e) => {
    e.preventDefault();

    const newRecording = {
      inputText: this.state.inputText,
      voice: this.state.voice,
      sampleRate: this.state.sampleRate,
      encoding: this.state.encoding
    }

    this.props.previewRecording(newRecording);
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { voices } = this.props;

    var voiceResult = voices.find(el => el.name === this.state.voice);

    var gender = voiceResult.ssmlGender;

    console.log("GENDER: " + JSON.stringify(gender));

    const newRecording = {
      fileName: this.state.fileName,
      inputText: this.state.inputText,
      gender: gender,
      voice: this.state.voice,
      displayVoice: 'test',
      sampleRate: this.state.sampleRate,
      encoding: this.state.encoding,
      audioPreset: this.state.audioPreset
    }

    // Check if we are updating an existing recording
    if (this.state.id) {
      newRecording._id = this.state.id;
      this.props.updateRecording(newRecording);
    } else {
      this.props.createRecording(newRecording);
    }
  };

  stopPreviewRecording = (id) => {
    this.props.stopPreviewRecording(id);
  }

  createVoiceList() {
    const { voices } = this.props;

    voices.sort((a, b) => a.name.localeCompare(b.name));

    var langCodes = langCodeData.lang_codes;

    let options = [];
    for (let i = 0; i < voices.length; i++) {
      let langEntry = langCodes.find(el => el.code === voices[i].languageCodes[0]);
      if (voices[i] && langEntry) {
        options.push(<option key={i} value={voices[i].name}>{langEntry.name + ' ' + voices[i].ssmlGender.charAt(0) + voices[i].ssmlGender.slice(1).toLowerCase() + ' ' + voices[i].name.slice(voices[i].name.length - 1)}</option>);
      }
    }
    return options;
  }

  createLanguageList() {
    const { languages } = this.props;

    console.log("Langs:" + languages);
    languages.sort((a, b) => a.name.localeCompare(b.name));

    let options = [];
    for (let i = 0; i < languages.length; i++) {
      options.push(<option key={i} value={languages[i].code}>{languages[i].name}</option>);
    }
    return options;
  }

  translate(selectedLang) {
    this.props.translate(this.state.inputText, selectedLang);
    this.setState({ language: selectedLang })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputText !== this.props.inputText) {
      this.setState({
        inputText: this.props.inputText
      });
    }
  }

  render() {
    return (
      <div className="flex -mx-2">
        <div className="w-full md:w-2/3 self-start bg-white p-6 shadow-sm rounded-sm mx-2">
          <header>
            <div>
              <h1 className="text-3xl font-bold leading-tight text-gray-900 ">
                Create Recording
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                Create your recording by entering your text and selecting a voice, then click save.
              </p>
            </div>
          </header>
          <main>
            <div className="">

              <div className="mt-6">
                <form onSubmit={this.onSubmit}>
                  <label htmlFor="fileName" className="block text-sm font-medium text-gray-700">
                    Name the recording
                  </label>

                  <input
                    id="fileName"
                    name="fileName"
                    value={this.state.fileName}
                    className="my-2 outline-none focus:border-blue-500 border-gray-300 border-2 block w-full sm:text-sm rounded-sm p-2"
                    placeholder="My New Recording"
                    onChange={this.onChange}
                  />

                  <label htmlFor="inputText" className="block text-sm font-medium text-gray-700 mt-6">
                    Enter the text you'd like to have converted to audio
                  </label>
                  <textarea name='inputText'
                    id="inputText"
                    value={this.state.inputText}
                    className="my-2 outline-none focus:border-blue-500 border-gray-300 border-2 block w-full sm:text-sm rounded-sm p-2 h-48"
                    placeholder="Enter text..."
                    onChange={this.onChange}
                  />

                  <p className="mt-6 text-gray-500 text-sm border-t border-gray-200 pt-6">
                    These settings can be used as they are for most recordings, or adjusted to your needs.
                  </p>

                  <div className="md:flex -mx-2">
                    <div className="md:w-1/2 px-2">
                      <label htmlFor="voice" className="block text-sm font-medium text-gray-700 mt-4">Select Voice</label>
                      <select
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-sm"
                        id="voice-select"
                        name="voice"
                        value={this.state.voice}
                        onChange={this.onChange}>
                        {this.createVoiceList()}
                      </select>
                    </div>
                    <div className="md:w-1/2 px-2">
                      <label htmlFor="language" className="block text-sm font-medium text-gray-700 mt-4">Translate Into</label>
                      <select
                        id="language-select"
                        name="language"
                        value={this.state.language}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-sm"
                        onChange={(e) => this.translate(e.target.value)}>
                        {this.createLanguageList()}
                      </select>
                    </div>
                  </div>
		<div className="mt-4 text-gray-500 text-sm">
			You can choose an audio preset like 3CX to match your needs, or choose custom to select the file type and sample rate yourself.
		</div>
                  <div className="md:flex -mx-2">
                    <div className="md:w-1/3 px-2">
                      <label htmlFor="audioPreset" className="block text-sm font-medium text-gray-700 mt-4">Choose Audio Preset</label>
                      <select id="audioPreset" name="audioPreset" value={this.state.audioPreset}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-sm"
                        onChange={this.onChange}>
                        <option value="Custom">Custom</option>
                        <option value="3CX">3CX</option>
                      </select>
                    </div>
                    <div className="md:w-1/3 px-2">
                      <label htmlFor="voice" className="block text-sm font-medium text-gray-700 mt-4">Choose File Type</label>
                      <select id="encoding" name="encoding" value={this.state.encoding}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-sm"
                        onChange={this.onChange}>
                        <option value="MP3">.mp3</option>
                        <option value="LINEAR16">.wav</option>
                      </select>
                    </div>
                    <div className="md:w-1/3 px-2">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700 mt-4">Sample Rate</label>
                      <select id="sample-rate-select" name="sampleRate" value={this.state.sampleRate} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-sm" onChange={this.onChange}>
                        <option value="48000">48000 Hz</option>
                        <option value="16000">16000 Hz</option>
                        <option value="8000">8000 Hz</option>
                      </select>
                    </div>
                  </div >
                  <div className="mt-6">
                    <button onClick={this.onPreview} data-name={"play-btn-" + this._id} className="bg-blue-600 hover:bg-blue-500 text-white w-32 mt-1 font-bold py-2 px-4 mr-2 shadow-md rounded-sm"><FontAwesomeIcon icon="play" size="1x" fixedWidth /> Preview</button>
                    <button onClick={(e) => { e.preventDefault(); this.stopPreviewRecording(this._id) }} data-name={"stop-btn-" + this._id} className="hidden w-32 bg-blue-600 hover:bg-blue-500 text-white mt-1 font-bold py-2 px-4 mr-2 shadow-md rounded-sm">Stop</button>
                    <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 ml-2 mt-1 shadow-md rounded-sm">Save</button>
                  </div>

                </form >

              </div >

            </div >
          </main >
        </div >
        <div className="hidden md:block md:w-1/3 mx-2">
          <div className="bg-white p-6 shadow-sm rounded-sm">
            <h2 className="text-3xl mb-2">How it works</h2>
            <div className="mb-3">Making a recording is as easy as naming it and entering the text you'd like to see spoken aloud. Our AI speech generators will provide you with audio that you can record or save instantly.</div>
            <div className="text-xl text-gray-700">Automatic translation</div>
            <div className="mb-4">
              Robotalk can translate your text automatically! Just select another language in the dropdown list and your text will automatically be translated into that language.
            </div>
            <div className="text-xl text-gray-700">Choose your voice</div>
            <div className="mb-4">
              Want to try a different voice? Just select one from the Voices dropdown list and your text will be spoken with a completely different voice.
              If you translate your text, make sure that you choose a voice that speaks that language (noted in brackets), or the result might sound a bit funny.
            </div>
            <div className="text-xl text-gray-700">File types and sample rate</div>
            <div className="mb-4">
              If you need need a specific file type and sample rate, you can choose those in their respective dropdown lists. The lower the sample rate, the lower the audio quality but the smaller the file size will be.
            </div>
            <div className="text-xl text-gray-700">Download your recording</div>
            <div className="mb-4">
              Once you're happy with your recording, click save and you'll be taken back to your list of recordings, with that recording at the top. You can click the download icon to have your file sent to your device.
            </div>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  voices: state.recording.voices,
  languages: state.recording.languages,
  inputText: state.recording.translation
});

export default connect(mapStateToProps, { getVoiceList, getLanguageList, translate, createRecording, updateRecording, previewRecording, stopPreviewRecording })(CreateRecording);
