import instance from '../api';
import { returnErrors } from "./errorActions";
import history from "../history";
import { toast } from 'react-toastify';

import{
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    TOKEN_RESENT,
    TOKEN_RESEND_FAIL,
    RESET_SENT,
    SEND_RESET_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL
} from "./types";

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: USER_LOADING });

    instance.get(`/auth/user`, tokenConfig(getState))
        .then(res => dispatch({
            type: USER_LOADED,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            });
        });
};

// Register User
export const register = ({ firstName, lastName, email, password }) => dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Request body
    const body = JSON.stringify( {firstName, lastName, email, password});

    instance.post(`/users`, body, config)
        .then(res => {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
          });
          history.push(`/registered`);
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
            dispatch({
                type: REGISTER_FAIL
            });
        });
};

// Login User
export const login = ({ email, password }) => dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Request body
    const body = JSON.stringify( {email, password});

    instance.post(`/auth`, body, config)
        .then(res => {dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
          });
          history.push(`/`);
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};

export const resendVerification = ( email ) => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const body = JSON.stringify( {email} );

  instance.post(`/users/resendToken`, body, config)
    .then(res => dispatch({
      type: TOKEN_RESENT,
      payload: res.data
    }))
    .then(history.push({
        pathname: '/verification',
        state: { email: email, isOpen: true }
      })) // To-do: is this the best way to do this?
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, 'TOKEN_RESEND_FAIL'));
      dispatch({
        type: TOKEN_RESEND_FAIL
      });
      history.push({
        pathname: '/verification',
        state: { verificationErr: 'An error occurred. Please try again later.', isOpen: true }
      }) // To-do: is this the best way to do this?;
    })
}

export const sendPasswordReset = ( email ) => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const body = JSON.stringify( { email } );

  instance.post(`/users/sendReset`, body, config)
    .then(res => dispatch({
      type: RESET_SENT,
      payload: res.data
    }))
    .then(() => { history.push({
        pathname: '/',
        state: { email: email}
      });
      toast.success(`Password reset email sent to ${email}`, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, 'SEND_RESET_FAIL'));
      dispatch({
        type: SEND_RESET_FAIL
      });
    })
}

export const resetPassword = ( token, password ) => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const body = JSON.stringify( { password } );
  instance.post(`/users/resetPassword/${token}`, body, config)
    .then(res => dispatch({
      type: PASSWORD_RESET_SUCCESS,
      payload: res.data
    }))
    .then(() => { history.push({
        pathname: '/'
      });
      toast.success(`Password reset successful. You may now log in.`, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, 'SEND_RESET_FAIL'));
      dispatch({
        type: PASSWORD_RESET_FAIL
      });
    })
}

// Logout User
export const logout = () => (dispatch, getState) => {
  instance.get(`/auth/logout`, tokenConfig(getState))
    .then(res => dispatch({
      type: LOGOUT_SUCCESS,
      payload: res.data
    }))
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, 'LOGOUT_FAIL'));
      dispatch({
        type: LOGOUT_FAIL
      });
    });
};

// Setup config/headers and token
export const tokenConfig = getState => {
    // Get token from localstorage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}