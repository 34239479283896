import { CREATE_RECORDING, UPDATE_RECORDING, PREVIEW_RECORDING, DOWNLOAD_RECORDING, RECORDINGS_LOADING, GET_RECORDINGS, DELETE_RECORDING, GET_VOICE_LIST, GET_LANGUAGE_LIST, TRANSLATE_TEXT } from '../actions/types';

const initialState = {
  recordings: [],
  voices: [],
  languages: [],
  loading: false,
  translation: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECORDINGS:
      return {
        ...state,
        recordings: action.payload,
        loading: false
      };
    case GET_VOICE_LIST:
      return {
        ...state,
        voices: action.payload
      };
    case GET_LANGUAGE_LIST:
      return {
        ...state,
        languages: action.payload
      };
    case TRANSLATE_TEXT:
      return {
        ...state,
        translation: action.payload
      };
    case DELETE_RECORDING:
      return {
        ...state,
        recordings: state.recordings.filter(recording => recording._id !== action.payload)
      };
    case DOWNLOAD_RECORDING:
      return {
        ...state,
      };
    case CREATE_RECORDING:
      return {
        ...state,
        recordings: [action.payload, ...state.recordings]
      };
    case UPDATE_RECORDING:
      return {
        ...state,
        recordings: [action.payload, ...state.recordings.filter(recording => recording._id !== action.payload._id)]
      };
    case PREVIEW_RECORDING:
      return {
        ...state,
      };
    case RECORDINGS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}