import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, resendVerification } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as EmailValidator from 'email-validator';
import { Link } from "react-router-dom";
import RegisterModal from './RegisterModal';

const initialState = {
  isOpen: false,
  email: '',
  password: '',
  serverErr: null,
  emailErr: null,
  passwordErr: null,
  verificationEmail: null
}

const noErrorState = {
  serverErr: null,
  emailErr: null,
  passwordErr: null,
  verificationEmail: null
}

class LoginModal extends Component {
  state = initialState;

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    resendVerification: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === 'LOGIN_FAIL') {
        this.setState({ serverErr: error.msg.msg });
        if (error.msg.type) {
          if (error.msg.type === 'not-verified') {
            this.setState({
              verificationEmail: error.msg.email
            });
          } else {
            this.setState({
              verificationEmail: null
            });
          }
        }
      } else {
        this.setState({
          serverErr: null,
          verificationEmail: null
        });
      }
    }

    // If authenticated, close modal
    if (this.state.isOpen) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = (action, elem_trigger) => {
    if (this.state.isOpen) {
      // clear form
      this.setState(initialState);
    }

    // Clear errors
    this.props.clearErrors();
    this.setState({
      isOpen: !this.state.isOpen
    });
    setTimeout(() => { if (this.state.isOpen) this.emailInput.focus(); }, 50);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validate = () => {
    let emailErr = "";
    let passwordErr = "";
    let serverErr = "";

    if (!EmailValidator.validate(this.state.email)) {
      emailErr = 'Please enter a valid email address.';
    }

    if (this.state.password.trim().length < 1) {
      passwordErr = 'Please enter your password.';
    }

    if (emailErr || passwordErr || serverErr) {
      this.setState({ emailErr, passwordErr, serverErr });
      return false;
    }
    return true;
  }

  onSubmit = e => {
    e.preventDefault();

    const isValid = this.validate();
    if (isValid) {
      const { email, password } = this.state;

      const user = {
        email,
        password
      }

      // clear form
      this.setState(noErrorState);

      // Attempt to login
      this.props.login(user);
    }
  };

  onResendVerification(email, e) {
    e.preventDefault();

    this.toggle();
    this.props.resendVerification(email);
  }

  render() {
    const { isOpen } = this.state;
    const { verificationEmail } = this.state;

    const link = <Fragment><button onClick={(e) => this.onResendVerification(verificationEmail, e)} style={{ cursor: 'pointer' }}><u className="text-blue-500 bg-transparent p-0 underline">Click here</u></button><label> to resend the verification email.</label></Fragment>;

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div className="text-center"><img className="mx-auto h-16 w-auto" src={process.env.PUBLIC_URL + '/assets/logo-icon.png'} alt="Robotalk" /></div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          {this.state.serverErr ? <div className="w-full px-3 pb-1 text-red-600 text-center mt-2">{this.state.serverErr}</div> : null}
          <form className="mt-8 space-y-6" onSubmit={this.onSubmit}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only" >Email address</label>
                <input id="email-address" name="email" type="email" autoComplete="email" required
                  ref={(input) => { this.emailInput = input; }}
                  value={this.state.email}
                  onChange={this.onChange}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                {this.state.emailErr ? <label className="text-red-600">{this.state.emailErr}</label> : null}
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input id="password" name="password" type="password" autoComplete="current-password" required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChange} />
                {this.state.passwordErr ? <label className="text-red-600">{this.state.passwordErr}</label> : null}
              </div>
            </div>


            <div>
              <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg className="h-5 w-5 text-blue-500 group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </span>
                  Sign in
                </button>
            </div>
          </form>
          <div className="text-center">
            <div className="text-sm">
              <a href="/reset-request" className="font-medium text-blue-600 hover:text-blue-500">
                Forgot your password?
                  </a>
            </div>
            <div className="text-center my-4 pt-4 border-t">
              Need an account? <a href="/register" className="font-medium text-blue-600 hover:text-blue-500">Register here.</a>
            </div>
          </div>

        </div >
      </div >

    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(mapStateToProps, { login, resendVerification, clearErrors })(LoginModal);
