import React, { Component } from 'react'

export default class RegistrationSuccess extends Component {
  render() {
    return (
      <div className="container max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-5 overflow-auto">
        <h1 className="text-center text-4xl font-medium text-blue-800 font-bold">You're almost there!</h1>
        <p className="mt-2 text-lg font-bold px-8">Thank you for registering for RoboTalk. Please follow the steps below to get started:</p>
        <div className="pt-8 pb-8 flex flex-col items-center">
          <div>
            <div className="py-4 flex flex-row items-center">
              <div className="rounded-full h-8 w-8 flex items-center justify-center text-lg bg-blue-800 text-white">1</div>
              <p className="pl-4 text-lg">Check your email for a verification email from us.</p>
            </div>
            <div className="py-4 flex flex-row items-center">
              <div className="rounded-full h-8 w-8 flex items-center justify-center text-lg bg-blue-800 text-white">2</div>
              <p className="pl-4 text-lg">Click the link in the email to activate your account.</p>
            </div>
            <div className="py-4 flex flex-row items-center">
              <div className="rounded-full h-8 w-8 flex items-center justify-center text-lg bg-blue-800 text-white">3</div>
              <p className="pl-4 text-lg">Log in and start creating recordings today.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
