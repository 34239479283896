import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as EmailValidator from 'email-validator';

const initialState = {
  isOpen: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confPassword: '',
  firstNameErr: null,
  lastNameErr: null,
  emailErr: null,
  passwordErr: null,
  serverErr: null
};

const noErrorState = {
  firstNameErr: null,
  lastNameErr: null,
  emailErr: null,
  passwordErr: null,
  serverErr: null
};

class RegisterModal extends Component {
  state = initialState;

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidMount() {
	if(window.location.pathname == "/register"){
		this.setState({isOpen: true});
	}

  }

  componentDidUpdate(prevProps) {
    const { error, isRegistered } = this.props;
    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === 'REGISTER_FAIL') {
        this.setState({ serverErr: error.msg.msg });
      } else {
        this.setState({
          serverErr: null
        });
      }
    }

    // If authenticated, close modal
    if (this.state.isOpen) {
      if (isRegistered) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    if (this.state.isOpen) {
      // clear form
      this.setState(initialState);
    }

    // Clear errors
    this.props.clearErrors();
    this.setState({
      isOpen: !this.state.isOpen
    });
    setTimeout(() => { if (this.state.isOpen) this.firstNameInput.focus(); }, 50);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    let firstNameErr = "";
    let lastNameErr = "";
    let emailErr = "";
    let passwordErr = "";
    let serverErr = "";

    if (this.state.firstName.trim().length < 1) {
      firstNameErr = '*Required';
    }
    if (this.state.lastName.trim().length < 1) {
      lastNameErr = '*Required';
    }
    if (!EmailValidator.validate(this.state.email)) {
      emailErr = 'Please enter a valid email address.';
    }
    if ((this.state.password.length < 5) || (!/\d/.test(this.state.password))) {
      passwordErr = 'Password must be 5 or more characters and contain at least 1 number.';
    }
    if ((this.state.password !== this.state.confPassword)) {
      passwordErr = 'Passwords do not match.';
    }

    if (firstNameErr || lastNameErr || emailErr || passwordErr || serverErr) {
      this.setState({ firstNameErr, lastNameErr, emailErr, passwordErr, serverErr });
      return false;
    }
    return true;
  }

  onSubmit = e => {
    e.preventDefault();

    const isValid = this.validate();
    if (isValid) {
      const { firstName, lastName, email, password } = this.state;

      // Create user object
      const newUser = {
        firstName,
        lastName,
        email,
        password
      };

      // clear form
      this.setState(noErrorState);

      // Attempt to register
      this.props.register(newUser);
    }
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div className="inline-block">

        <div id="centeredFormModal" className={`modal-wrapper ${isOpen ? "modal-is-open" : ""}`}>
          <div className="overlay close-modal" onClick={this.toggle} />
          <div className="modal modal-centered">
            <div className="modal-content shadow-lg p-5">
              <div className="border-b p-2 pb-3 pt-0 mb-5">
                <div className="flex justify-between items-center">
                  Registration
                                    <span className='close-modal cursor-pointer px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300' onClick={this.toggle}>
                    <FontAwesomeIcon icon="times" />
                  </span>
                </div>
              </div>
              {/* Modal content*/}

              <form id='register_form_id' className="w-full" onSubmit={this.onSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                  {this.state.serverErr ? <label className="w-full px-3 pb-1 text-red-600">{this.state.serverErr}</label> : null}
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1" htmlFor="first-name">First Name</label>
                    <input ref={(input) => { this.firstNameInput = input; }}
                      name="firstName"
                      className="appearance-none block w-full bg-gray-100 focus:bg-white text-gray-700 border border-gray-300 focus:border-blue-500 rounded py-3 px-4 mb-1 leading-tight focus:shadow-outline focus:outline-none"
                      id="first-name" type="text" placeholder="Jane" onChange={this.onChange} />
                    {this.state.firstNameErr ? <label className="text-red-600">{this.state.firstNameErr}</label> : null}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1" htmlFor="last-name">Last Name</label>
                    <input
                      name="lastName"
                      className="appearance-none block w-full bg-gray-100 focus:bg-white text-gray-700 border border-gray-300 focus:border-blue-500 rounded py-3 px-4 mb-1 leading-tight focus:shadow-outline focus:outline-none"
                      id="last-name" type="text" placeholder="Doe" onChange={this.onChange} />
                    {this.state.lastNameErr ? <label className="text-red-600">{this.state.lastNameErr}</label> : null}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1" htmlFor="email">Email</label>
                    <input name="email" className="appearance-none block w-full bg-gray-100 focus:bg-white text-gray-700 border border-gray-300 focus:border-blue-500 rounded py-3 px-4 mb-1 leading-tight focus:shadow-outline focus:outline-none"
                      id="email" autoComplete="username" type="text" placeholder="Email address" onChange={this.onChange} />
                    {this.state.emailErr ? <label className="text-red-600">{this.state.emailErr}</label> : null}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-8">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1" htmlFor="password">Password</label>
                    <input
                      name="password"
                      className="appearance-none block w-full bg-gray-100 focus:bg-white text-gray-700 border border-gray-300 focus:border-blue-500 rounded py-3 px-4 mb-1 leading-tight focus:shadow-outline focus:outline-none"
                      id="password" autoComplete="new-password" type="password" placeholder="**********" onChange={this.onChange} />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1" htmlFor="confirm-password">Confirm Password</label>
                    <input
                      name="confPassword"
                      className="appearance-none block w-full bg-gray-100 focus:bg-white text-gray-700 border border-gray-300 focus:border-blue-500 rounded py-3 px-4 mb-1 leading-tight focus:shadow-outline focus:outline-none"
                      id="confirm-password" autoComplete="new-password" type="password" placeholder="**********" onChange={this.onChange} />
                  </div>
                  {this.state.passwordErr ? <label className="w-full px-3 text-red-600">{this.state.passwordErr}</label> : null}
                </div>
                <div className="float-right">
                  <button className='cursor-default bg-gray-200 hover:bg-gray-300 text-gray-600 font-semibold py-2 px-4 rounded-sm mr-2' onClick={this.toggle} type="button"> Cancel </button>
                  <button className='bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-sm'> Submit </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isRegistered: state.auth.isRegistered,
  error: state.error
});

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);
