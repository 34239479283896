import React, { Component } from 'react'

export default class SayAsDropdown extends Component {
  constructor(props) {
    super(props)

    this.insertSayAs = this.insertSayAs.bind(this);

    this.state = {
      isOpen: false
    }
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  insertSayAs(e, typeOfInterpret) {
    e.preventDefault();

    var textArea = document.getElementById('inputText');

    //IE support
    if (document.selection) {
      textArea.focus();
      var sel = document.selection.createRange();
      sel.text = '<say-as interpret-as=\'' + typeOfInterpret + '\'/>';
    }
    //MOZILLA and others
    else if (textArea.selectionStart || textArea.selectionStart === '0') {
      var startPos = textArea.selectionStart;
      var endPos = textArea.selectionEnd;
      textArea.value = textArea.value.substring(0, startPos)
        + '<say-as interpret-as=\'' + typeOfInterpret + '\'>'
        + textArea.value.substring(startPos, endPos)
        + '</say-as>'
        + textArea.value.substring(endPos, textArea.value.length);
    } else {
      textArea.value += '<say-as interpret-as=\'' + typeOfInterpret + '\'></say-as>';
    }
    if (this.state.isOpen) {
      this.setState({
        isOpen: false
      });
    }
    this.props.onChange(textArea.value);
  }

  render() {
    var isOpen = this.state.isOpen;
    return (
      <div>
        <button tabIndex="-1" onClick={this.toggle} className="relative z-10 block bg-blue-700 hover:bg-blue-800 text-white font-bold py-1 px-4 mr-2 border border-teal-800 rounded">Interpret As:</button>
        {isOpen &&
          <div>

            <button onClick={this.toggle} tabIndex="-1" className="fixed inset-0 w-full h-full bg-black opacity-25 cursor-default"></button>

            <div className="absolute mt-2 py-2 w-30 bg-white rounded-lg border border-gray-500 shadow-xl">
              <button onClick={(e) => this.insertSayAs(e, 'spell-out')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Spell Out</button>
              <button onClick={(e) => this.insertSayAs(e, 'cardinal')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Cardinal</button>
              <button onClick={(e) => this.insertSayAs(e, 'ordinal')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Ordinal</button>
              <button onClick={(e) => this.insertSayAs(e, 'digits')} className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">Digits</button>
            </div>

          </div>
        }
      </div>
    )
  }
}
