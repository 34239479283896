import React, { Component, Fragment } from 'react'
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/authActions';
import AppNavBar from './components/AppNavBar';
import CreateRecording from './components/CreateRecording';
import MyRecordings from './components/MyRecordings';
import { Route, Router, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import history from './history';
import VerificationModal from './components/auth/VerificationModal';
import EmailConfirmation from './components/auth/EmailConfirmation';
import PasswordResetRequest from './components/auth/PasswordResetRequest';
import PasswordReset from './components/auth/PasswordReset';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegistrationSuccess from './components/auth/RegistrationSuccess';
import PropTypes from 'prop-types';
import LoginModal from './components/auth/LoginModal';
import RegisterModal from './components/auth/RegisterModal';
import { connect } from 'react-redux';
import Logout from './components/auth/Logout';

require('dotenv').config();

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    if (isAuthenticated) {
      return (
        <div>
          <div className="min-h-screen bg-gray-100">
            <AppNavBar />

            <div className="py-10 max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto">
              <ToastContainer className="toaster" position="top-center" />
              <Switch>
                <Fragment>
                  <div className="content">
                    <PrivateRoute exact path="/" component={MyRecordings} />
                    <PrivateRoute exact path="/create-recording" component={CreateRecording} />
                    <PrivateRoute exact path="/my-recordings" component={MyRecordings} />
                  </div>
                </Fragment>
              </Switch>
            </div>
          </div>
        </div>
      )
    } else if (isAuthenticated === false) {
      return (
        <div>
          <ToastContainer className="toaster" position="top-center" />
          <Switch>
            <Fragment>
              <Route exact path='/confirm/:token' component={EmailConfirmation} />
              <Route exact path="/verification" component={VerificationModal} />
              <Route exact path="/reset-request" component={PasswordResetRequest} />
              <Route exact path="/password-reset/:token" component={PasswordReset} />
              <Route exact path="/registered" component={RegistrationSuccess} />
              <Route exact path="/login" component={LoginModal} />
		 <Route exact path="/register" component={RegisterModal} />
              <Route exact path="/" component={LoginModal} />
            </Fragment>
          </Switch>
        </div>
      );
    } else {
      return (<div className="min-h-screen bg-gray-100"></div>)
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(App);

