import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterModal from './auth/RegisterModal';
import LoginModal from './auth/LoginModal';
import Logout from './auth/Logout';
//import Logout from './auth/Logout';

class AppNavBar extends Component {
  state = {
    navBarOpen: false,
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  }

  toggle = () => {
    this.setState({
      navBarOpen: !this.state.navBarOpen
    });
  }

  onMenuToggle = (e) => {
    document.getElementById("mobile-menu").classList.toggle("hidden");
    if (e.target.name === 'create-recording') {
      window.history.pushState(null, '');
      this.forceUpdate();
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <Fragment>
        <p className="block mt-4 lg:inline-block italic lg:mt-0 text-black mr-4">{user ? `Welcome back, ${user.firstName}` : ''}</p>
        <Logout />
      </Fragment>
    );

    const guestLinks = (
      <Fragment>
        <LoginModal />
        <RegisterModal />
      </Fragment>
    );

    if (isAuthenticated) {
      return (
        <nav className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <img className="block h-8 w-auto" src={process.env.PUBLIC_URL + '/assets/logo-icon.png'} alt="Robotalk" /> <span className="ml-2 text-xl font-extrabold">Robotalk</span>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  <Fragment>
                    <NavLink to="/my-recordings"
                      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      activeClassName="border-blue-500 text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      onClick={this.onMenuToggle}>
                      My Recordings
                    </NavLink>
                    <NavLink to="/create-recording"
                      name="create-recording"
                      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      activeClassName="border-blue-500 text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      onClick={this.onMenuToggle}>
                      Create Recording
                    </NavLink>
                  </Fragment>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <Logout />
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <button type="button" onClick={this.onMenuToggle} className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" aria-controls="mobile-menu" aria-expanded="false">
                  <span className="sr-only">Open main menu</span>

                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>

                  <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="hidden sm:hidden" id="mobile-menu">
            <Fragment>
              <div className="space-y-1">
                <NavLink to="/my-recordings"
                  className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  activeClassName="bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  onClick={this.onMenuToggle}>
                  My Recordings
                </NavLink>
              </div>
              <div className="space-y-1">
                <NavLink to="/create-recording"
                  name="create-recording"
                  className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  activeClassName="bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  onClick={this.onMenuToggle}>
                  Create Recording
                </NavLink>
              </div>
            </Fragment>
            <div className="mt-4 pt-4 pb-3 border-t border-gray-200 pl-3">
              <Logout />
            </div>
          </div>
        </nav>
      );
    } else {
      return (
        ""
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(AppNavBar);