import instance from '../api';
import { GET_RECORDINGS, GET_VOICE_LIST, TRANSLATE_TEXT, GET_LANGUAGE_LIST, CREATE_RECORDING, UPDATE_RECORDING, DELETE_RECORDING, RECORDINGS_LOADING } from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import history from "../history";

export const getRecordings = () => (dispatch, getState) => {
  dispatch(setRecordingsLoading());
  instance
    .get(`/recordings`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_RECORDINGS,
        payload: res.data
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const searchRecordings = (search) => (dispatch, getState) => {
  dispatch(setRecordingsLoading());
  instance
    .post(`/recordings/search`, { search: search }, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_RECORDINGS,
        payload: res.data
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getVoiceList = () => (dispatch, getState) => {
  instance.get(`/recordings/voices/all`, tokenConfig(getState))
    .then(res =>

      dispatch({
        type: GET_VOICE_LIST,
        payload: res.data
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getLanguageList = () => (dispatch, getState) => {
  instance.get(`/recordings/languages/all`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_LANGUAGE_LIST,
        payload: res.data
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const translate = (inputText, languageCode) => (dispatch, getState) => {
  const body = JSON.stringify({ inputText, languageCode });
  instance.post(`/recordings/translate`, body, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: TRANSLATE_TEXT,
        payload: res.data
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const createRecording = (recording) => (dispatch, getState) => {
  instance.post(`/recordings/synthesize`, recording, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: CREATE_RECORDING,
        payload: res.data
      })).then(history.push(`/my-recordings`)) // To-do: is this the best way to do this?
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updateRecording = (recording) => (dispatch, getState) => {
  instance.post(`/recordings/synthesize`, recording, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: UPDATE_RECORDING,
        payload: res.data
      })).then(history.push(`/my-recordings`)) // To-do: is this the best way to do this?
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const editRecording = (id) => (dispatch, getState) => {
  instance.get(`/recordings/${id}`, tokenConfig(getState))
    .then(res => {
      history.push({
        pathname: '/create-recording',
        state: { recording: res.data.recording }
      });
    })
}

export const previewRecording = (recording) => (dispatch, getState) => {
  instance(
    {
      url: `/recordings/preview`,
      method: 'POST',
      responseType: 'blob', // important
      headers: { "x-auth-token": getState().auth.token },
      data: recording
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      if (window.audio)
        window.audio.pause();
      window.audio = new Audio();
      window.audio.src = url;
      window.audio.play();
      let play_btn = document.body.querySelector('[data-name="play-btn-' + recording._id + '"]');
      let stop_btn = document.body.querySelector('[data-name="stop-btn-' + recording._id + '"]');
      play_btn.classList.add("hidden");
      stop_btn.classList.remove("hidden");
      window.audio.onended = function () {
        play_btn.classList.remove("hidden");
        stop_btn.classList.add("hidden");
      };
    });
};

export const stopPreviewRecording = (id) => (dispatch, getState) => {
  if (window.audio) {
    window.audio.pause();
    window.audio = null;
  }

  let play_btn = document.body.querySelector('[data-name="play-btn-' + id + '"]');
  let stop_btn = document.body.querySelector('[data-name="stop-btn-' + id + '"]');
  if (play_btn) {
    play_btn.classList.remove("hidden");
  }

  if (stop_btn) {
    stop_btn.classList.add("hidden");
  }
};

export const deleteRecording = (id) => (dispatch, getState) => {
  instance.delete(`/recordings/${id}`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: DELETE_RECORDING,
        payload: id
      }))
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const setRecordingsLoading = () => {
  return {
    type: RECORDINGS_LOADING
  };
};

export const onDownload = (e) => (dispatch, getState) => {
  e.persist();
  instance({
    url: `/recordings/download`,
    method: 'POST',
    responseType: 'blob', // important
    headers: { "x-auth-token": getState().auth.token },
    data: { id: e.currentTarget.getAttribute("data-id") }
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    var disposition = response.headers['content-disposition'];
    var filename = "recording.wav";
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });
};

export const onPlay = (e) => (dispatch, getState) => {
  e.persist();
  window.previewing_recording_id = e.currentTarget.getAttribute("data-id");
  instance({
    url: `/recordings/download`,
    method: 'POST',
    responseType: 'blob', // important
    headers: { "x-auth-token": getState().auth.token },
    data: { id: e.currentTarget.getAttribute("data-id") }
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    if (window.audio)
      window.audio.pause();
    window.audio = new Audio();
    window.audio.src = url;
    window.audio.play();

    let play_btn = document.body.querySelector('[data-name="play-btn-' + window.previewing_recording_id + '"]');
    let stop_btn = document.body.querySelector('[data-name="stop-btn-' + window.previewing_recording_id + '"]');
    play_btn.classList.add("hidden");
    stop_btn.classList.remove("hidden");
    window.audio.onended = function () {
      play_btn.classList.remove("hidden");
      stop_btn.classList.add("hidden");
    };
  });
};