import React, { Component } from 'react'
import instance from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import LoginModal from './LoginModal';

export default class EmailConfirmation extends Component {
  state = {
    confirming: true,
    message: null
  }

  componentDidMount = () => {
    const { token } = this.props.match.params;

    instance.post(`${process.env.REACT_APP_API_SERVER_URL}/users/confirmation/${token}`)
      .then(res => {
        this.setState({ confirming: false, message: res.data.msg });
        toast.success(`${this.state.message}`, {
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ confirming: false, message: err });
          toast.error(`${err.response.data.msg}`, {
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }

      })
  }

  render() {
    return (
      <div>
        <div className='flex justify-center fadein'>
          {this.state.confirming
            ? <FontAwesomeIcon icon="sync-alt" size="4x" fixedWidth className="mt-24 fa-spin" /> : null
          }
        </div>
        <LoginModal />
      </div>

    )
  }
}
